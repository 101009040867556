import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles, TextField, Typography, Button } from "@material-ui/core";
import { Element } from "react-scroll";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { createNota, getFlorsNotes } from "../../../../database/API";
import { useNavigate, useParams } from "react-router";
import ScrollAnimation from "react-animate-on-scroll";
import { Grid } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	main: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		paddingBottom: 100,
		paddingTop: 100,
	},
	button: {
		boxShadow: "none",
		borderRadius: 30,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 30,
		paddingRight: 30,
		textTransform: "none",
	},
	models: {
		display: "flex",
		flexDirection: "columns",
		marginTop: 20,
		marginLeft: 15,
	},
	model: {
		color: theme.palette.text.secondary,
		paddingRight: 8,
		paddingLeft: 8,
		cursor: "pointer",
	},
	media: {
		width: "100%",
		height: 380,
		objectFit: "contain",
	},
	imageWrap: {
		width: 300,
		height: 400,
		borderRadius: 30,
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
	},
	imageWrapSelected: {
		width: 300,
		height: 400,
		borderRadius: 30,
		backgroundColor: "white",
		border: "3px solid",
		borderColor: theme.palette.text.secondary,
		display: "flex",
		alignItems: "center",
	},
}));

const Input = withStyles((theme) => ({
	root: {
		"& .MuiOutlinedInput-root": {
			borderRadius: 30,
			backgroundColor: "white",
			"& fieldset": {
				borderRadius: 30,
				borderColor: theme.palette.background.black,
			},
			"&.Mui-focused fieldset": {
				borderRadius: 30,
			},
		},
		"& .MuiOutlinedInput-input:-webkit-autofill": {
			borderRadius: 30,
		},
	},
}))(TextField);

const Notes = (props) => {
	const classes = useStyles();
	const { key } = useParams();
	const [height, setHeight] = useState(0);
	const [height_inv, setHeightInv] = useState("auto");
	const [flors, setFlors] = useState([]);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { difunt } = props;
	const { t } = useTranslation();
	useEffect(() => {
		const get = async () => {
			const { flors } = await getFlorsNotes(true);
			setFlors(flors);
		};
		get();
	}, []);

	const enviar = async (values, actions) => {
		const message = await createNota(values, difunt);
		enqueueSnackbar(message, {
			variant: "success",
		});
		actions.setSubmitting(false);
		actions.resetForm({});
	};

	const fillNota = (nota, setFieldValue) => {
		if (nota === 1) setFieldValue("nota", t("Rebeu el nostre més sincer condol per tan sentida pèrdua."));
		else if (nota === 2) setFieldValue("nota", t("Estem al vostre costat en tan dolorosos moments."));
		else if (nota === 3) setFieldValue("nota", t("Mai t'oblidarem, sempre estaràs amb nosaltres."));
		else if (nota === 4) setFieldValue("nota", t("La direcció i empleats de (...) ens unim al dol de la família."));
		else if (nota === 5)
			setFieldValue("nota", t("Els socis i la Junta Directiva de (...) expressem el nostre més sincer condol a la família per tan sentida pèrdua."));
		else if (nota === 6) setFieldValue("nota", t("Els companys de (...) mai t'oblidarem, sempre estaràs amb nosaltres."));
	};

	Yup.addMethod(Yup.string, "integer", function () {
		return this.matches(/^\d+$/, this.transform("El camp només pot contenir dígits"));
	});

	const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

	return (
		<Element name="notes" className={classes.main}>
			<ScrollAnimation animateIn="fadeIn" style={{ transition: "0.5s" }}>
				<Container maxWidth="lg">
					<Box mb={3}>
						<Typography variant="h2">{t("Notes de condol")}</Typography>
						<Typography>
							{t(
								"Les notes de condol seran revisades pel nostre personal abans de ser lliurades als familiars del difunt, i només s'acceptaran les que siguin enviades fins una hora abans de la cerimònia."
							)}
						</Typography>
					</Box>

					<Formik
						initialValues={{
							nom: "",
							telefon: "",
							email: "",
							nota: "",
							opcio: false,
							flor: "",
							accepta: false,
						}}
						validationSchema={Yup.object().shape({
							nom: Yup.string().max(255).required(t("El nom és obligatòri")),
							email: Yup.string().email(t("Ha de ser un email vàlid")).max(255).required(t("L'email és obligatòri")),

							telefon: Yup.string()
								.matches(phoneRegExp, t("Telèfon no vàlid"))
								.when("opcio", {
									is: true,
									then: Yup.string().matches(phoneRegExp, t("Telèfon no vàlid")).required(t("El telèfon és obligatòri")),
								}),

							nota: Yup.string().max(350, t("Has superat el límit de la nota")).required(t("El missatge és obligatòri")),
							accepta: Yup.bool().oneOf([true], t("Has d'acceptar la Política de Privacitat per continuar")),
						})}
						onSubmit={(values, actions) => {
							enviar(values, actions);
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							setSubmitting,
							isSubmitting,
							setFieldValue,
							touched,
							validateForm,
							values,
							setTouched,
						}) => (
							<form onSubmit={handleSubmit}>
								<Input
									error={Boolean(touched.nom && errors.nom)}
									fullWidth
									helperText={touched.nom && errors.nom}
									label={t("Nom i cognoms")}
									margin="normal"
									name="nom"
									className={classes.input}
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									value={values.nom}
									variant="outlined"
								/>
								<Input
									error={Boolean(touched.telefon && errors.telefon)}
									fullWidth
									helperText={touched.telefon && errors.telefon}
									label={t("Telèfon")}
									margin="normal"
									name="telefon"
									className={classes.input}
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									value={values.telefon}
									variant="outlined"
								/>
								<Input
									error={Boolean(touched.email && errors.email)}
									fullWidth
									helperText={touched.email && errors.email}
									label="E-mail"
									margin="normal"
									name="email"
									className={classes.input}
									onBlur={handleBlur}
									onChange={handleChange}
									type="email"
									value={values.email}
									variant="outlined"
								/>
								<Box className={classes.models}>
									<Typography>{t("Models predefinits")}:</Typography>
									<Typography className={classes.model} onClick={() => fillNota(1, setFieldValue)}>
										1
									</Typography>
									<Typography className={classes.model} onClick={() => fillNota(2, setFieldValue)}>
										2
									</Typography>
									<Typography className={classes.model} onClick={() => fillNota(3, setFieldValue)}>
										3
									</Typography>
									<Typography className={classes.model} onClick={() => fillNota(4, setFieldValue)}>
										4
									</Typography>
									<Typography className={classes.model} onClick={() => fillNota(5, setFieldValue)}>
										5
									</Typography>
									<Typography className={classes.model} onClick={() => fillNota(6, setFieldValue)}>
										6
									</Typography>
								</Box>
								<Input
									error={Boolean(touched.nota && errors.nota)}
									fullWidth
									helperText={touched.nota && errors.nota}
									label={t("Nota")}
									margin="normal"
									name="nota"
									className={classes.input}
									onBlur={handleBlur}
									onChange={handleChange}
									type="text"
									multiline
									value={values.nota}
									variant="outlined"
									rows={11}
									inputProps={{ maxLength: 350 }}
									placeholder="Màxim 350 caràcters"
								/>
								<Box mb={3}>
									<Typography style={{ fontSize: 14 }}>Caràcters: {values.nota?.length}/350</Typography>
								</Box>
								<Grid container>
									<Grid item md={12}>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.opcio}
													onChange={(e) => {
														setFieldValue("opcio", !values.opcio);
														setFieldValue("flor", flors[0]);
														setHeight(e.target.checked ? "auto" : 0);

														setHeightInv(e.target.checked ? 0 : "auto");
													}}
													name="opcio"
												/>
											}
											label={t("Opció de Nota de condol en una rosa")}
										/>
									</Grid>
								</Grid>
								<FormControlLabel
									control={
										<Checkbox
											checked={values.accepta}
											onChange={(e) => {
												setFieldValue("accepta", e.target.checked);
											}}
											name="accepta"
										/>
									}
									label={
										<Typography>
											{t("He llegit i accepto la")}{" "}
											<a href="/politica-privacitat" target="_blank">
												{t("Política de Privacitat")}
											</a>
										</Typography>
									}
								/>
								<ErrorMessage name="accepta">
									{(msg) => (
										<Typography variant="body1" style={{ color: "red" }}>
											{msg}
										</Typography>
									)}
								</ErrorMessage>
								<AnimateHeight height={height} duration={500}>
									<Box my={4}>
										<Grid container spacing={5}>
											{flors?.map((item) => {
												return (
													<Grid
														item
														md={4}
														onClick={() => {
															setFieldValue("flor", item);
														}}
														key={item.id}
													>
														<Box className={values.flor.id === item.id ? classes.imageWrapSelected : classes.imageWrap}>
															<img
																src={"https://api.funerariaferran.com/public/storage/" + item.imatge}
																className={classes.media}
																alt="flor"
															/>
														</Box>
													</Grid>
												);
											})}
										</Grid>
									</Box>
									<Button
										color="primary"
										onClick={() =>
											validateForm().then((validation) => {
												setTouched(validation);
												!Object.keys(errors).length &&
													navigate("compra", {
														state: {
															id: difunt,
															flor: values.flor,
															values: values,
														},
													});
											})
										}
										className={classes.button}
										variant="contained"
									>
										{"Comprar"}
									</Button>
								</AnimateHeight>
								<AnimateHeight height={height_inv} duration={500}>
									<Box my={2}>
										<Button color="primary" disabled={isSubmitting} className={classes.button} type="submit" variant="contained">
											{"Enviar"}
										</Button>
									</Box>
								</AnimateHeight>
							</form>
						)}
					</Formik>
				</Container>
			</ScrollAnimation>
		</Element>
	);
};

export default Notes;
