import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import "moment/locale/ca";
import DifuntEdit from "./DifuntEdit";
import { Button } from "@material-ui/core";
import { createVivo, deleteImage, getVivo } from "../../../../database/API";
import { useSnackbar } from "notistack";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogContentText } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CustomTextField from "../../../../components/CustomTextField";
import moment from "moment";

const DialogVivoRecuerdo = ({ open, setOpen, difunt, setVivoApi }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		// resolver: yupResolver(schemaInscripcioAdmin),
		mode: "onTouched",
	});

	useEffect(() => {
		const setValuesForm = () => {
			reset(difunt);
			setValue("data_defuncio", moment(difunt.data_defuncio).format("YYYY-MM-DD"));
			setValue("data_naixement", moment(difunt.data_naixement).format("YYYY-MM-DD"));
			setValue("dia_cerimonia", difunt.dia_cerimonia ? moment(difunt.dia_cerimonia).format("YYYY-MM-DD") : "");
			setValue("hora_cerimonia", difunt?.hora_cerimonia ? moment(difunt?.hora_cerimonia, "HH:mm:ss").format("HH:mm") : "");
			setValue("nombre_sala", Number(difunt?.sala) === 1 ? "Sala 1" : "Sala 2");
			setValue("texto_adicional", difunt?.horari_tanatori);
			setValue("poblacion_cementerio_crematorio", difunt?.desti);
			setValue("fecha_fin_servicio", difunt.dia_cerimonia ? moment(difunt.dia_cerimonia).format("YYYY-MM-DD") : "");
			setValue("hora_fin_servicio", difunt?.hora_cerimonia ? moment(difunt?.hora_cerimonia, "HH:mm:ss").add(1, "hours").format("HH:mm") : "");
		};
		open && setValuesForm();
	}, [open]);

	const crearVivo = async (values) => {
		setLoading(true);
		console.log(values);
		values.difunt_id = difunt.id;
		const { message, success, data } = await createVivo(constructForm(values));
		if (success) {
			setOpen(false);
			setVivoApi(data);
			enqueueSnackbar(message, {
				variant: "success",
			});
		} else {
			enqueueSnackbar("Dades incorrectes. Comprova els camps", {
				variant: "error",
			});
		}
		setLoading(false);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
			<form onSubmit={handleSubmit(crearVivo)}>
				<DialogTitle id="alert-dialog-title">{"Crear difunt a Vivo Recuerdo"}</DialogTitle>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="nom"
								label="Nom"
								errors={errors.name}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="cognoms"
								label="Cognoms"
								errors={errors.cognoms}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="data_naixement"
								label="Data naixement"
								type={"date"}
								errors={errors.data_naixement}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="data_defuncio"
								label="Data defunció"
								errors={errors.data_defuncio}
								type={"date"}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={3}>
							<FormControl fullWidth required>
								<InputLabel htmlFor="tipus">Sala</InputLabel>
								<Select
									defaultValue={Number(difunt?.sala) === 1 ? "Sala 1" : "Sala 2"}
									{...register("nombre_sala")}
									fullWidth
									label={"Nom sala"}
									error={Boolean(errors?.nombre_sala)}
								>
									<MenuItem selected value={"Sala 1"}>
										Sala 1
									</MenuItem>
									<MenuItem value={"Sala 2"}>Sala 2</MenuItem>
								</Select>
								<Typography variant="caption" color="#e32420">
									{errors?.nombre_sala?.message}
								</Typography>
							</FormControl>
						</Grid>
						<Grid item md={6}>
							<CustomTextField
								register={register}
								name="texto_adicional"
								label="Text adicional"
								errors={errors.texto_adicional}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="sobrenom"
								label="Sobrenom"
								errors={errors.sobrenom}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="dia_cerimonia"
								label="Dia cerimònia"
								type={"date"}
								errors={errors.dia_cerimonia}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="hora_cerimonia"
								label="Hora cerimònia"
								type="time"
								errors={errors.hora_cerimonia}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="fecha_fin_servicio"
								label="Dia final cerimònia"
								type={"date"}
								errors={errors.fecha_fin_servicio}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="hora_fin_servicio"
								label="Hora final cerimònia"
								type="time"
								errors={errors.hora_fin_servicio}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="cerimonia"
								label="Lloc cerimònia"
								errors={errors.cerimonia}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="poblacion_ceremonia"
								label="Població cerimònia"
								errors={errors.poblacion_ceremonia}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="poblacion_cementerio_crematorio"
								label="Població cementiri/crematori"
								errors={errors.poblacion_cementerio_crematorio}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="localidad"
								label="Localitat de defunció"
								errors={errors.localidad}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>

						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="lugar_nacimiento"
								label="Lloc naixement"
								errors={errors.lugar_nacimiento}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>
						<Grid item md={4}>
							<CustomTextField
								register={register}
								name="provincia_pais_nacimiento"
								label="Província o país de naixement"
								errors={errors.provincia_pais_nacimiento}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>

						<Grid item md={4}>
							<FormControl fullWidth displayEmpty>
								<InputLabel htmlFor="tipus">Cementiri/Crematori</InputLabel>
								<Select
									{...register("cementerio_crematorio")}
									fullWidth
									label={"Cementori/Crematori"}
									error={Boolean(errors?.cementerio_crematorio)}
								>
									<MenuItem value={"cementerio"}>Cementiri</MenuItem>
									<MenuItem value={"crematorio"}>Crematori</MenuItem>
								</Select>
								<Typography variant="caption" color="#e32420">
									{errors?.cementerio_crematorio?.message}
								</Typography>
							</FormControl>
							{/* <CustomTextField
								register={register}
								name="cementerio_crematorio"
								label="Cementiri o crematòri"
								errors={errors.cementerio_crematorio}
								InputLabelProps={{
									shrink: true,
								}}
								// required
							/> */}
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_1"
								label="Telèfon administrador 1"
								errors={errors.tlf_admin_1}
								InputLabelProps={{
									shrink: true,
								}}
								required
							/>
						</Grid>

						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_2"
								label="Telèfon administrador 2"
								errors={errors.tlf_admin_2}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_3"
								label="Telèfon administrador 3"
								errors={errors.tlf_admin_3}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={3}>
							<CustomTextField
								register={register}
								name="tlf_admin_4"
								label="Telèfon administrador 4"
								errors={errors.tlf_admin_4}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} className={classes.eliminar}>
						Cancel·lar
					</Button>
					{loading ? (
						<Button disabled className={classes.cancelar}>
							<CircularProgress color="white" style={{ width: 25, height: 25 }} />
						</Button>
					) : (
						<Button type="submit" autoFocus className={classes.cancelar}>
							Publicar a Vivo Recuerdo
						</Button>
					)}
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default DialogVivoRecuerdo;

const useStyles = makeStyles((theme) => ({
	imatge: {
		width: "100%",
		aspectRatio: 1 / 1,
		height: "auto",
	},
	column: {
		backgroundColor: "white",
		borderRadius: 5,
		padding: 30,
		boxShadow: "-1px 2px 4px -1px rgb(0 0 0 / 3%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 4%)",
	},
	nom: {
		textAlign: "center",
		marginBottom: 20,
	},
	eliminar: {
		backgroundColor: theme.palette.danger.main,
		color: "white",
		marginTop: 20,
		"&:hover": {
			backgroundColor: theme.palette.danger.light,
		},
	},
	cancelar: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: 20,
	},
}));

export function constructForm(values) {
	const data = new FormData();

	data.append("fecha_defuncion", moment(values.data_defuncio).format("DD/MM/YYYY"));
	data.append("localidad", values.localidad);
	data.append("nombre", values.nom);
	data.append("apellidos", values.cognoms);
	data.append("sobrenombre", values.sobrenom ?? "");
	data.append("fecha_nacimiento", moment(values.data_naixement).format("DD/MM/YYYY"));
	data.append("lugar_nacimiento", values.lugar_nacimiento);
	data.append("provincia_pais_nacimiento", values.provincia_pais_nacimiento);
	data.append("fecha_ceremonia", values.dia_cerimonia ? moment(values.dia_cerimonia).format("DD/MM/YYYY") : "");
	data.append("lugar_ceremonia", values.cerimonia ?? "");
	data.append("poblacion_ceremonia", values.poblacion_ceremonia);
	data.append("hora_ceremonia", values.hora_cerimonia ? moment(values.hora_cerimonia, "HH:mm:ss").format("HH:mm") : "");
	data.append("cementerio_crematorio", values.cementerio_crematorio);
	data.append("poblacion_cementerio_crematorio", values.poblacion_cementerio_crematorio);
	data.append("nombre_opcional_cementerio_crematorio", values.nombre_opcional_cementerio_crematorio);
	data.append("texto_adicional", values.texto_adicional);
	data.append("nombre_sala", values.nombre_sala);
	data.append("idioma_velatorio", "ca_ES");
	data.append("fecha_fin_servicio", values.fecha_fin_servicio ? moment(values.fecha_fin_servicio).format("DD/MM/YYYY") : "");
	data.append("hora_fin_servicio", values.hora_fin_servicio ? moment(values.hora_fin_servicio, "HH:mm:ss").format("HH:mm") : "");
	data.append("tlf_admin_1", values.tlf_admin_1);
	data.append("tlf_admin_2", values.tlf_admin_2);
	data.append("tlf_admin_3", values.tlf_admin_3);
	data.append("tlf_admin_4", values.tlf_admin_4);

	data.append("id", values.id);

	for (var pair of data.entries()) {
		console.log(pair);
	}
	return data;
}
